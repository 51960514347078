<template>
	<div>
		<div class="simple-title">Verify Your Details<span class="fal fa-eye"></span></div>
		<div class="form-wrapper small">
			<div class="form-container">
			<div class="text">
				<p>Please verify the details of your account below to finish signing up. If anything looks wrong, please correct it here.</p><br>
			</div>

			<form @submit.prevent="onSubmit">
				<form-errors :errors="errors"></form-errors>

				<field-errors v-if="submitAttempted" :form="$v.form" field="first_name"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">First Name</div>
					<div class="input-box"><input type="text" v-model="form.first_name"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="last_name"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Last Name</div>
					<div class="input-box"><input type="text" v-model="form.last_name"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="phone_number"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Phone Number</div>
					<div class="input-box"><input type="text" v-model="form.phone_number" placeholder="321-555-5273" v-mask="['(###) ###-####']"></div>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
				<div v-if="form.type === 'STANDARD'">
					<!-- Standard Auth -->
					<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
					<div class="input-wrapper">
						<div class="input-title">Email Address</div>
						<div class="input-box"><input type="text" v-model="form.email"></div>
					</div>
				</div>
				<div v-if="form.type !== 'STANDARD'">
					<!-- Social Auth -->
					<input-tooltip>
						<span slot="title">Email Address</span>
						<div class="input-title">Email Address</div>
						<div slot="field"><input type="text" :value="form.email" disabled readonly></div>
						<div slot="tooltip">
							Your email address was set by using social authentication and cannot be changed.
						</div>
					</input-tooltip>
				</div>

				<submit-button text="Save" icon="fa-key" :loading="formSaving"></submit-button>
			</form>
			</div>
		</div>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import { email, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {

	name: 'CustomerSignupStepTwoPage',

	mixins: [ FormMixin ],

	data() {
		return {
			form: {
				email: null,
				stepTwo: true,
			},
			completed: false,
		};
	},

	computed: mapGetters([ 'currentUser' ]),

	validations: {
		form: {
			first_name: { required },
			last_name: { required },
			email: {
				required,
				email,
			},
			phone_number: { required },
		}
	},

	mounted() {
		this.form = { ...this.currentUser };
	},

	beforeRouteLeave(to, from, next) {
		if (!this.completed) {
			next(false);
		} else {
			next();
		}
	},

	methods: {

		async onSubmit() {
			this.submit(async () => {
				await this.$api.post('/user', this.form);
				await this.$store.dispatch('getAccounts');
				this.completed = true;

				this.$nextTick(() => {
					this.$store.dispatch('goIntended', '/browse/list');
				});
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to update your information. Please try again.');
			});


		}
	}
};

</script>
